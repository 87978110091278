import React from "react"

import Layout from "../components/web/layout"
import SEO from "../components/web/seo"
import ArticleList from "../components/web/article_list"

const IndexPage = (props) => {
  return (
    <Layout>
      <SEO title="Home" />
      <p>This site is home to conversations about unconventional math problems, 
        how to crack them, and their surprising solutions.
      </p>
      <ArticleList header_size="small"></ArticleList>
      <p>Reach us at <a href = "mailto: alex@mathmouth.com">alex@mathmouth.com</a> or <a href = "mailto: jack@mathmouth.com">jack@mathmouth.com</a> . </p>
    </Layout>
  )
}

export default IndexPage
